import { AppFunctionComponent } from "../../../types"
import React from "react"
import useTranslationHook from "../../../hooks/use-translation.hook"
import { Path } from "../../../navigation/constants/routes"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { TextLink } from "../../../ui/components/link/link.component"
import { PostDate } from "../../common/general-styles.component"
import {
  PrimaryHeading,
  SecondaryHeading,
} from "../../common/heading.component"
import StandardSection, {
  SectionImage,
  SectionLayout,
} from "../../common/sections/section.component"
import { renderBlogPost } from "../../community/blog/blog-post-rich-text-options"
import PressReleaseDownloadContent from "../press-release-download-content/press-release-download-content.component"
import SocialMediaShare from "../social-media-share/social-media-share.component"
import {
  Back,
  Contacts,
  HeaderArticle,
  PostArticle,
  PostContent,
  PostHeader,
  PostWrapper,
  ShareLinks,
  SocialLinks,
} from "./single-press-release.styled"
import { SinglePressReleaseProps } from "./single-press-release.types"

const SinglePressRelease: AppFunctionComponent<SinglePressReleaseProps> = ({
  className = "",
  pressRelease: {
    slug,
    title,
    content,
    featuredImage: { gatsbyImageData },
    originalPublishDate,
    additionalInfo,
    linkToAssets,
    linkToPdf,
    mediaContacts,
  },
}) => {
  const { blogPostContent } = renderBlogPost(content)
  const { translate } = useTranslationHook()
  return (
    <PostWrapper className={className}>
      <PostHeader>
        <StandardSection layout={SectionLayout.ImageText}>
          <Back to={Path.Media}>{translate("Back")}</Back>
          <SectionImage disableShadow image={gatsbyImageData} loading="eager" />
          <HeaderArticle>
            <PostDate dateTime={originalPublishDate}>
              {originalPublishDate}
            </PostDate>
            <PrimaryHeading renderAs={"h1"}>{title}</PrimaryHeading>
            {additionalInfo && <RichText content={additionalInfo} />}
            <PressReleaseDownloadContent
              linkToAssets={linkToAssets}
              linkToPdf={linkToPdf}
            />
          </HeaderArticle>
        </StandardSection>
      </PostHeader>
      <PostContent>
        <SocialLinks>
          <SocialMediaShare slug={slug} title={title} />
        </SocialLinks>
        <PostArticle>
          {blogPostContent}
          {(linkToAssets || linkToPdf) && (
            <StandardSection>
              <PressReleaseDownloadContent
                linkToAssets={linkToAssets}
                linkToPdf={linkToPdf}
              />
            </StandardSection>
          )}
          <StandardSection>
            <SecondaryHeading renderAs={"h2"}>
              {translate("press-share-article")}
            </SecondaryHeading>
            <ShareLinks>
              <SocialMediaShare slug={slug} title={title} />
            </ShareLinks>
          </StandardSection>
          <StandardSection>
            {mediaContacts && (
              <>
                <SecondaryHeading renderAs={"h2"}>
                  {translate("press-media-contacts")}
                </SecondaryHeading>
                <Contacts>
                  {mediaContacts.map((mediaContact, index) => (
                    <div key={index}>
                      <p>{mediaContact.personName}</p>
                      <TextLink to={mediaContact.email}>
                        {mediaContact.email}
                      </TextLink>
                    </div>
                  ))}
                </Contacts>
              </>
            )}
          </StandardSection>
        </PostArticle>
      </PostContent>
    </PostWrapper>
  )
}

export default SinglePressRelease
