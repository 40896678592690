import styled from "styled-components"
import BackLink from "../../../navigation/components/back-link/back-link.component"
import { NeutralTextLink, TextLink } from "../../../ui"
import {
  footnotesAnchors,
  gridSpacing,
  PostDate,
  quoteStyles,
} from "../../common/general-styles.component"
import { PrimaryHeading } from "../../common/heading.component"
import StandardSection, {
  SectionArticle,
  SectionImage,
} from "../../common/sections/section.component"

export const PostWrapper = styled.article`
  ${gridSpacing};
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: var(--grid-spacing);
`

export const HeaderArticle = styled(SectionArticle)`
  padding-top: 0;
  padding-bottom: 0;
  ${PrimaryHeading} {
    margin-bottom: 0;
    word-break: break-word;
  }

  ${PostDate} {
    margin: 2px 30px 10px 0;
    line-height: ${14 / 16}rem;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    flex-direction: column;
  }

  ${NeutralTextLink} {
    display: block;
    line-height: 1;
    & + ${NeutralTextLink} {
      margin-left: 20px;

      @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
`

export const PostHeader = styled.header`
  ${SectionImage} {
    margin-bottom: 0;
    max-height: 460px;
  }
`

export const PostArticle = styled(SectionArticle)`
  margin: 0 auto;
  max-width: 640px;

  blockquote:not(+ article) {
    ${quoteStyles};
    margin: 30px 0;
    @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
      margin: 60px -40px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
      margin: 90px -80px;
    }

    p {
      font-size: ${23 / 16}rem;
      line-height: ${40 / 23}em;
    }
    p,
    cite {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ul {
    display: block;
    padding-left: 1.8em;
  }
  li {
    margin-bottom: 1em;
  }
  ${StandardSection} {
    width: 100%;
    --padding: 30px;
    padding: var(--padding) 0;

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
      --padding: 60px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
      --padding: 90px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + ${StandardSection} {
      padding-top: 0;
    }
  }
  ${footnotesAnchors};
  ${StandardSection} {
    padding: calc(var(--padding) / 1.5) 0;

    + * {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

export const PostContent = styled(StandardSection)`
  display: grid;
  grid-row-gap: 30px;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas: "Social" "Article" ".";

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-template-columns: 2fr 8fr;
    grid-template-areas: "Social Article";
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-areas: "Social Article .";
  }

  ${PostArticle} {
    padding: 0;
  }
`

export const Back = styled(BackLink)`
  margin-bottom: 30px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-top: 0;
  }
`

export const ShareLinks = styled.div`
  display: flex;
  flex-direction: row;

  img {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding-right: 30px;
    margin: 0;
  }
`

export const Contacts = styled.div`
  display: grid;
  grid-template-columns: auto;
  gird-template-rows: auto;

  ${TextLink} {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    grid-template-columns: auto auto;

    ${TextLink} {
      margin-bottom: 0;
    }
  }
`
