import { AppFunctionComponent } from "../../types"
import React, { ComponentProps } from "react"
import {
  SectionImageDescription,
  SectionVideo,
} from "../../components/common/sections/section.component"
import { Video } from "../../types"
import styled, { css } from "styled-components"
import { getSrc } from "gatsby-plugin-image"

const VideoEmbed = styled(SectionVideo)`
  object-fit: cover;
  max-height: calc(100% - 20px);
`

const InternalVideo = styled(VideoEmbed)<{ objectFit?: string }>`
  padding-bottom: 0;

  ${({ objectFit }) =>
    objectFit &&
    css`
      border-radius: 8px;
      border: 2px solid #cdcdcd;

      video {
        object-fit: ${objectFit};
      }

      @media (min-width: 1000px) {
        max-width: 556px;
        max-height: 556px;
      }
    `}
`

const InternalVideoWithPlaceholder = styled(VideoEmbed)`
  padding-bottom: 0;
  border-radius: 20px;
  video {
    object-fit: cover;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    max-width: 100%;
    max-height: calc(100vw - 40px);
  }
`

const ExternalVideo = styled(VideoEmbed)`
  padding-bottom: ${(9 / 16) * 100}%;
`

const VideoDescription = styled(SectionImageDescription)`
  margin-top: 1em;
  margin-bottom: 0;
`

const VideoResolver: AppFunctionComponent<Video> = (video) => {
  const {
    url,
    media,
    videoDescription,
    autoplay,
    loop,
    controlsPanel,
    height,
    objectFit,
    placeholder,
  } = video
  const description = videoDescription?.videoDescription

  const internalUrl = media?.file.url

  const videoConfig: ComponentProps<typeof SectionVideo> = {
    controls: controlsPanel,
    muted: autoplay, // This is required for autoplaying
    playing: autoplay,
    loop,
  }

  if (internalUrl) {
    if (placeholder) {
      return (
        <>
          <InternalVideoWithPlaceholder
            height={placeholder.height}
            width={placeholder.width}
            url={internalUrl}
            objectFit={"contain"}
            playsinline
            playing
            autoplay
            light={getSrc(placeholder)}
          />
          <VideoDescription>{description}</VideoDescription>
        </>
      )
    } else {
      return (
        <>
          <InternalVideo
            height={height || "auto"}
            width={"100%"}
            url={internalUrl}
            objectFit={objectFit}
            playsinline
            {...videoConfig}
          />
          <VideoDescription>{description}</VideoDescription>
        </>
      )
    }
  }

  if (url) {
    return (
      <>
        <ExternalVideo height={"0"} width={"100%"} url={url} {...videoConfig} />
        <VideoDescription>{description}</VideoDescription>
      </>
    )
  }

  return null
}

export default VideoResolver
