import React from "react"
import { AppFunctionComponent } from "../../../types"
import { DownloadContentProps } from "./press-release-download-content.types"
import { TextLink } from "../../../ui/components/link/link.component"
import { DownloadContent } from "./press-release-download-content.styled"
import useTranslationHook from "../../../hooks/use-translation.hook"

const PressReleaseDownloadContent: AppFunctionComponent<
  DownloadContentProps
> = ({ linkToPdf, linkToAssets }) => {
  const { translate } = useTranslationHook()
  return (
    <DownloadContent>
      {linkToPdf && (
        <TextLink to={linkToPdf}>
          {translate("press-download-this-release")} (pdf)
        </TextLink>
      )}
      {linkToAssets && (
        <TextLink to={linkToAssets}>{translate("press-download-all")}</TextLink>
      )}
    </DownloadContent>
  )
}

export default PressReleaseDownloadContent
