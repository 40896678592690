import React, { KeyboardEvent, ReactNode, useState } from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import Modal, { ModalType } from "../modal/modal.component"

const Wrapper = styled.div`
  outline: none;
  cursor: pointer;
`

interface Props {
  zoomedContent: ReactNode
  preventZoom?: boolean
  children?: React.ReactNode
}

const Zoomable: AppFunctionComponent<Props> = ({
  children,
  preventZoom,
  zoomedContent,
}) => {
  const [modalOpened, setModalOpened] = useState(false)
  const openModal = () => setModalOpened(true)
  const closeModal = () => setModalOpened(false)
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      openModal()
    }
    if (event.key === "Escape") {
      closeModal()
    }
  }

  if (preventZoom) {
    return <>{children}</>
  }

  return (
    <>
      <Wrapper
        onClick={openModal}
        onKeyDown={onKeyDown}
        role={"button"}
        aria-label={"Zoom it"}
        tabIndex={0}
      >
        {children}
      </Wrapper>
      <Modal
        modalType={ModalType.Raw}
        active={modalOpened}
        onClose={closeModal}
      >
        {zoomedContent}
      </Modal>
    </>
  )
}

export default Zoomable
